import React, { useDebugValue, useEffect, useState } from 'react';
import Button from '../../components/Button';
import toast from 'react-hot-toast';
import { deleteBankCard, updateBankCard } from '../../reducers/bank-action';

function EditBankCard({ card, onCancel, onUpdate, token }) {
    const [editingCard, setEditingCard] = useState({ ...card }); // Load the card data into local state
    const [cashoutType] = useState(card.upi==null?0:1); // Set initial cashout type, not changeable

    // Handle input change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditingCard(prev => ({ ...prev, [name]: value }));
    };
    const handleDeleteCard = async (cardId) => {
        try {
            token= localStorage.getItem('token');
            await deleteBankCard(token, cardId); 
            window.location.href="/bank-card"
            // setCards(fetchedCards);
        } catch (error) {
            console.error('Error deleting card', error);
        }
    };
    // Handle card update submission
    const handleUpdateCard = async () => {
        try {
            token= localStorage.getItem('token');  // Ensure cashout type stays the same
            const response = await updateBankCard(token, editingCard.id, editingCard);
            if (response.success) {
                toast.success('Card updated successfully!');
            } else {
                toast.error('Error updating card');
            }
            window.location.href='/bank-card';
        } catch (error) {
            console.error('Error updating card', error);
            toast.error('An error occurred while updating the card');
        }
    };
  
    return (
        <div className="card-form">
            <form onSubmit={handleUpdateCard}>
                {/* Cashout Type is set and not editable */}
                
                {cashoutType === 0 && (
                    <input
                        type="text"
                        name="ifsc"
                        value={editingCard.ifsc}
                        onChange={handleChange}
                        placeholder="IFSC Code"
                        required
                    />
                )}
                {cashoutType === 1 && (
                    <input
                        type="text"
                        name="ifsc"
                        value={editingCard.ifsc}
                        onChange={handleChange}
                        placeholder="UPI Id"
                        required
                    />
                )}

                <input
                    type="text"
                    name="person_name"
                    value={editingCard.person_name}
                    onChange={handleChange}
                    placeholder="Actual Name"
                    required
                />

                <input
                    type="number"
                    name="mobile_number"
                    value={editingCard.mobile_number}
                    onChange={handleChange}
                    placeholder="Mobile Number"
                    required
                />

                {cashoutType === 0 && (
                    <input
                        type="email"
                        name="email"
                        value={editingCard.email}
                        onChange={handleChange}
                        placeholder="Email"
                        required
                    />
                )}

                <input
                    type="text"
                    name="bank_name"
                    value={editingCard.bank_name}
                    onChange={handleChange}
                    placeholder="Bank Name"
                    required
                />

                {cashoutType === 0 && (
                    <input
                        type="text"
                        name="account_number"
                        value={editingCard.account_number}
                        onChange={handleChange}
                        placeholder="Account Number"
                        required
                    />
                )}

                <ul style={{ marginTop: '10px', display: 'flex', flexDirection:'row' }}>
                    <li style={{ width: '100%' }}>
                        <Button clickTo={()=>handleDeleteCard(card.id)} label="Delete" btnClass="btn-primary-outline w-100" custom={true} />
                    </li>
                    <li style={{ width: '100%' }}>
                        <Button type="button" clickTo={()=>handleUpdateCard()} label="Continue" btnClass="btn-primary" />
                    </li>
                    
                </ul>
            </form>
        </div>
    );
}

export default EditBankCard;
