import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GuestHeader from '../../components/GuestHeader';
import AuthHeader from '../../components/AuthHeader';
import Button from '../../components/Button';
import toast from 'react-hot-toast'; 
import { loadTeam } from '../../reducers/bank-action';
// Assuming `applyBonusToBalance` is an API function
import { applyBalance} from '../../reducers/bank-action'; 

function Promotion() {
    const [level, setLevel] = useState(1);
    const dispatch = useDispatch(); 
    const user = useSelector(state => state.auth.user);
    const team = useSelector(state => state.auth.team); // Get team data from Redux

    // Function to fetch and set team data
    const fetchAndSetTeam = async () => {
        try {
            const token = localStorage.getItem('token');
            const teamData = await loadTeam(token); // Load team data
            dispatch({type:'TEAM', payload:teamData}); // Dispatch action to set team
        } catch (error) {
            console.error('Failed to load team:', error);
        }
    };

    useEffect(() => {
        fetchAndSetTeam(); // Load team data on component mount
    }, []); // Empty array means it will only run on mount

    // Function to handle Apply all to Balance
    const handleApplyAllToBalance = async () => {
        if (team && team.bonus) {
            try {
                const token = localStorage.getItem('token');
                const resp = await applyBalance(token);
                toast.success(`${resp.message}`);
                window.location.href = '/promotion'; // Optionally reload or update state instead of reload
            } catch (error) {
                toast.error('Failed to apply bonus.');
            }
        } else {
            toast.error('No bonus available to apply.');
        }
    };

    const copyTo = () => {
        if (user && user.ref_code) {
            const baseUrl = window.location.hostname === 'localhost'
                ? 'https://royale98.in/register?code='
                : 'https://royale98.in/register?code=';
            const referralLink = `${baseUrl}${user.ref_code}`;

            if (navigator.clipboard && navigator.clipboard.writeText) {
                navigator.clipboard.writeText(referralLink)
                    .then(() => toast.success('Referral link copied to clipboard!'))
                    .catch(err => {
                        toast.error('Failed to copy referral link.');
                        console.error('Failed to copy text: ', err);
                    });
            } else {
                fallbackCopyTextToClipboard(referralLink);
            }
        } else {
            toast.error('No referral code available.');
        }
    };

    const copyCodeTo = () => {
        if (user && user.ref_code) {
            if (navigator.clipboard && navigator.clipboard.writeText) {
                navigator.clipboard.writeText(user.ref_code)
                    .then(() => toast.success('Referral code copied to clipboard!'))
                    .catch(err => {
                        toast.error('Failed to copy referral code.');
                        console.error('Failed to copy text: ', err);
                    });
            } else {
                fallbackCopyTextToClipboard(user.ref_code);
            }
        } else {
            toast.error('No referral code available.');
        }
    };

    function fallbackCopyTextToClipboard(text) {
        const textArea = document.createElement('textarea');
        textArea.value = text;
        textArea.style.position = 'fixed';  // Prevents scrolling to bottom of page in MS Edge.
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
            const successful = document.execCommand('copy');
            toast.success(`Fallback: Copying text command was ${successful ? 'successful' : 'unsuccessful'}`);
        } catch (err) {
            toast.error('Fallback: Oops, unable to copy');
            console.error('Fallback: Unable to copy', err);
        }
        document.body.removeChild(textArea);
    }

    return (
        <>
            <AuthHeader pageTitle="Promotions" />
            {team && (
                <div style={{ background: '#fff', marginTop: '2px', paddingBlock: '20px' }}>
                    <p style={{ paddingInline: '20px', paddingBlockEnd: '10px' }}>
                        Bonus: <span style={{ fontWeight:'500',fontSize:'15px' }}>{team.bonus ?? 0}</span>
                    </p>
                    {/* Trigger applyAllToBalance on button click */}
                    <Button label="Apply all to Balance" btnClass="btn-primary" clickTo={handleApplyAllToBalance} />
                </div>
            )}
            {team && (
                <div style={{ background: '#fff', marginTop: '20px', fontSize: '12px' }}>
                    <ul className='row row-between'>
                        <li className={level === 1 ? `level_option active` : `level_option`} onClick={() => setLevel(1)}>
                            Level 1
                        </li>
                        <li className={level === 2 ? `level_option active` : `level_option`} onClick={() => setLevel(2)}>
                            Level 2
                        </li>
                    </ul>
                    <div style={{ paddingInline: '10px' }}>
                        <ul className='row row-between' style={{ padding: '20px' }}>
                            <li style={{ textAlign: 'center' }}>
                                <p style={{ color: '#bebebe' }}>Total People</p>
                                <p style={{ marginTop: '10px',fontWeight:'500',fontSize:'15px' }} >
                                    {level === 1 ? team.level1.length ?? 0 : team.level2.length ?? 0}
                                </p>
                            </li>
                            <li style={{ textAlign: 'center' }}>
                                <p style={{ color: '#bebebe' }}>Contribution</p>
                                <p style={{ marginTop: '10px',fontWeight:'500',fontSize:'15px' }}>
                                    {level === 1 ? team.level1_contribution : team.level2_contribution}
                                </p>
                            </li>
                        </ul>
                        <p style={{ marginBottom: '10px', color: '#bebebe' }}>My Promotion Code</p>
                        <p style={{ marginBottom: '10px' }}>{user.ref_code}</p>
                        <hr />
                        <p style={{ marginBlock: '10px', color: '#bebebe' }}>My Promotion Link</p>
                        <p style={{ marginBottom: '10px',fontSize:'16px',fontWeight:'500' }}>
                            https://royale98.in/register?code={user.ref_code}
                        </p>
                        <ul className='row row-around p-5'>
                            <li style={{ flex: '40%', paddingInline: '5px' }}>
                                <Button label={"Copy Code"} btnClass="btn-primary-outline w-100" clickTo={copyCodeTo} custom={true} />
                            </li>
                            <li style={{ flex: '40%', paddingInline: '5px' }}>
                                <Button label={"Copy Link"} btnClass="btn-primary-outline w-100" clickTo={copyTo} custom={true} />
                            </li>
                        </ul>
                        <br />
                    </div>
                </div>
            )}
        </>
    );
}

export default Promotion;
