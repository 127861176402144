import React, { useState } from 'react'
import OptionsTab from '../../components/OptionsTab'
import PeriodCountdown from '../../components/PeriodCountdown'
import GuessButton from '../../components/GuessButton'
import GameResultRecord from '../../components/GameResultRecord'
import GameOrderRecord from '../../components/GameOrderRecord'
import Button from '../../components/Button';
import { ArrowPathIcon } from '@heroicons/react/24/outline'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'


function Guess() {
  const dispatch = useDispatch();
  
  const user = useSelector(state=>state.auth.user);
  const userWallet = useSelector(state=>state.auth.wallet);
  const navigate = useNavigate();
  const [ruleOpen,setRuleOpen]=useState(false);
  const loadBalance= async()=>{
    
    const token= localStorage.getItem('token')
    const defaultHead={
      headers: {
          'Authorization': `Bearer ${token}`
      }
    };
    const baseUrl= 'https://backend.royale98.in/api/v1';
    const response = await axios.get(baseUrl+"/me",defaultHead);
            
    dispatch({ type: 'SET_USER_WALLET', payload: response.data.wallet });
  }
  /*
  Syncing data
  Balance
  Guess Record
  Button color & clickable
  */
  return (
   <> 
   {ruleOpen==true && 
   <div className='rules-box'>
      <div className='rules-container'>
      <h1>Rule of Guess</h1>

      <h2>Game Structure</h2>
      <ul>
          <li>3 minutes for 1 issue</li>
          <li>2 minutes and 30 seconds to place your order</li>
          <li>30 seconds to display the lottery result</li>
          <li>Open all day</li>
          <li>Total number of trades: 480 issues</li>
      </ul>

      <h2>Trading Details</h2>
      <p>If you spend $100 to trade, after deducting a $2 service fee, your contract amount is $98:</p>

      <h3>Join Options</h3>
      <ul>
          <li><strong>JOIN GREEN:</strong>
              <ul>
                  <li>If the result shows 1, 3, 7, 9: you will get <strong>$196</strong> (98 * 2)</li>
                  <li>If the result shows 5: you will get <strong>$147</strong> (98 * 1.5)</li>
              </ul>
          </li>
          <li><strong>JOIN RED:</strong>
              <ul>
                  <li>If the result shows 2, 4, 6, 8: you will get <strong>$196</strong> (98 * 2)</li>
                  <li>If the result shows 0: you will get <strong>$147</strong> (98 * 1.5)</li>
              </ul>
          </li>
          <li><strong>JOIN VIOLET:</strong>
              <ul>
                  <li>If the result is 0 or 5: you will get <strong>$441</strong> (98 * 4.5)</li>
              </ul>
          </li>
          <li><strong>SELECT NUMBER:</strong>
              <ul>
                  <li>If the result is the same as your selected number: you will get <strong>$882</strong> (98 * 9)</li>
              </ul>
          </li>
      </ul>
      <Button clickTo={()=>setRuleOpen(false)} label="Close" />
      </div>
   </div>
   }
   <div className='profile_main'>
   <div className='profile__card'>
    <br/>
    <div className='row row-start w-90'>
      <h4 style={{marginInlineEnd:'10px'}}>Available balance : {userWallet}</h4>
      <h4> <ArrowPathIcon onClick={()=>loadBalance()} style={{width:'20px',height:'20px'}}/> </h4>
    </div>
    
    <ul class='row row-start w-90 mt-5 mb-10'>
      <li style={{marginInlineEnd:'20px',width:'100px'}}>
        <Button label={"Recharge"} btnClass="btn-primary" clickTo={()=>navigate('/recharge')}   custom={true}/>
      </li>
      <li>
        <Button  label="Read Rule" btnClass="btn-primary-outline" clickTo={()=>setRuleOpen(true)}  custom={true}/>
      </li>
       
    </ul>  
   </div>
   </div> 
   <OptionsTab/>
   <PeriodCountdown/>
   <GuessButton/>
   <GameResultRecord/>
   <br/>

   <GameOrderRecord/>

   </>
  )
}

export default Guess