import React, { useEffect, useState } from 'react';
import GuestHeader from '../../components/GuestHeader';
import AuthHeader from '../../components/AuthHeader';
import Input from '../../components/Input';
import Button from '../../components/Button';
import TextArea from '../../components/TextArea'; // Assuming you have a TextArea component
import { useDispatch, useSelector } from 'react-redux';
import { loadReport, loadTeam } from '../../reducers/bank-action';

function ReportForm() {
  const reports = useSelector(state=>state.auth.report);
  const dispatch = useDispatch(); 
  
  useEffect(()=>{
    const fetchData = async () => {
  const report = await loadReport(localStorage.getItem('token'));
   dispatch({type:'REPORT',payload:report  });
    }
    fetchData();
  },[])
  const reportCards = [
    'Subordinates',
    'Today Registrations',
    'Today bet members',
    'Today Login members',
    'Today fist recharge',
    'Total recharge amount',
    'Today first-recharge members',
    'Total withdrawl amount'
  ];


  

  return (
    <>
      <AuthHeader pageTitle="Submit Report" />
      <div className='reports-div'>
        {reports==null &&
      <ul>
         {reportCards.map((e)=> 
         <li>
          <div className='report-card'>
            loading please wait.....
          </div>
         </li>
         )}
      </ul>
        }
        {reports != null &&
        <ul>
        {reports.map((e)=> 
        <li>
         <div className='report-card'>
         
            <p>{e.name}</p>
            <p style={{fontSize:'14px',fontWeight:'bold',marginBlock:'10px'}}>{e.value}</p>
            {e.first_level &&<>
            <p>First level</p>
            <p>
              {e.first_level}
            </p>
            <p>Second level</p>
            <p>
              {e.second_level}
            </p>
            </>}
         </div>
        </li>
        )}
     </ul>
        }
      </div>
     </>
  );
}

export default ReportForm;
