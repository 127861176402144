import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';

function Input({ prepend, placeholder, type = 'text', label, onChange, value, trailing = '', readable = false,showPass= true }) {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(prevState => !prevState);
  };

  const inputType = type === 'password' && !isPasswordVisible ? 'password' : 'text';

  if (type === 'checkbox') {
    return (
      <ul className='input-ul'>
        <li>{prepend}</li>
        <li>
          <input
            readOnly={readable}
            type={type}
            onChange={onChange}
            checked={value} // For checkbox, you need to handle the checked state
          />
        </li>
        <li className='label'>{label}</li>
      </ul>
    );
  }

  return (
    <div className='input-box'>
      <div>{prepend}</div>
      <div>
        <input
          placeholder={placeholder}
          type={inputType}
          onChange={onChange}
          readOnly={readable}
          value={value} // For text inputs, handle the value prop
        />
        {(type === 'password' && showPass==true) && (
          <span className='togg-btn'  onClick={togglePasswordVisibility}>
            {isPasswordVisible ? <EyeIcon/> : <EyeSlashIcon/> }
          </span>
        )}
      </div>
      {trailing !== '' && (
        <div style={{ maxWidth: '100px' }}>{trailing}</div>
      )}
    </div>
  );
}

export default Input;
