import React, { useEffect } from 'react'
import AuthHeader from '../../components/AuthHeader'
import { useDispatch, useSelector } from 'react-redux';
import { loadReport, loadTeam, loadTransactions } from '../../reducers/bank-action';


function WithdrawalHistory() {
  const transactions = useSelector(state=>state.auth.transactions);
  const dispatch = useDispatch(); 
  
  useEffect(()=>{
    const fetchData = async () => {
  const report = await loadTransactions(localStorage.getItem('token'));
   dispatch({type:'TRANSACTIONS',payload:report  });
    }
    fetchData();
  },[])
  
  return (
    <>
    <AuthHeader pageTitle="Withdraw History"/>
    <div className='transactions'>
        {(transactions==null || transactions.withdraws.length<1) &&
        <div className='flex-col h-40'>
          <p>
            Withdraw History Will Appear Here
            </p>
          </div>
        }
        {transactions != null &&
        <>
        {transactions.withdraws.map((e)=> 
        <>
        <ul>
         
        <li key={e.id} style={{fontSize:'12px'}}>
          Cash Withdrawal
        <span className={e.status==1?`badge badge-success`: e.status==2?`badge badge-failed`:`badge badge-processing`}> {e.status==1?'Success':e.status==2?'Failed':'Processing'}</span>
        <p style={{fontSize:'10px',color:'#888'}}>{e.updated_at}</p>
        <p style={{fontSize:'10px',color:'orange'}}>{e.reject_reason}</p>
</li>
<li>
  {e.amount}
</li>

        </ul>
        </>
        )} </>
        }
      </div>
    </>
  )
}

export default WithdrawalHistory