import React, { useEffect, useState } from 'react'; 
import AuthHeader from '../../components/AuthHeader';
import Input from '../../components/Input';
import Button from '../../components/Button';
import toast from 'react-hot-toast';
import { loadTeam, recharge } from '../../reducers/bank-action';
import { Link, useNavigate } from 'react-router-dom';
import { ListAlt } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';

function Recharge() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [token] = useState(localStorage.getItem('token'));
  const amounts = [100, 300, 500, 1000, 2000, 5000];
  const rechargeOptions = ['Seppay', 'Wonpay', 'Matrixpay'];
  const user = useSelector(state => state.auth.user);
  const team = useSelector(state => state.auth.team);
  const userWallet = useSelector(state => state.auth.wallet);
  
  const fetchAndSetTeam = async () => {
    try {
        const token = localStorage.getItem('token');
        const teamData = await loadTeam(token); // Load team data
        dispatch({type:'TEAM', payload:teamData}); // Dispatch action to set team
    } catch (error) {
        console.error('Failed to load team:', error);
    }
};
  const [selectedAmount, setSelectedAmount] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [balance, setBalance] = useState(30); // Assume initial balance

  const handleAmountChange = (amount) => {
    setSelectedAmount(amount);
  };

  const handleRecharge = async () => {
    if (!selectedAmount || !selectedOption) {
      toast.error("Please select an amount and a payment option.");
      return;
    }

    try {// Replace with the actual token logic
      
      const response = await recharge(token, {
        user_id: user.id, // Replace with actual user ID
        amount: selectedAmount,
        payment_option: selectedOption,
      });

      if (response.success) {
        // setBalance(prevBalance => prevBalance + parseInt(selectedAmount, 10)); // Update balance
        toast.success(response.message);
        localStorage.setItem('amount',selectedAmount)
        navigate('/verify/'+response.redirect_code)
      } else {
        toast.error("Recharge failed: " + response.message);
      }
    } catch (error) {
      console.error("Error during recharge:", error);
      toast.error("An error occurred while processing your request.");
    }
  };
  useEffect(()=>{
  fetchAndSetTeam()
  },[])

  return (
    <>
      <AuthHeader pageTitle="Recharge" trailing={<Link to="/recharge/history" ><ListAlt /></Link>} />
      <div style={{ background: '#fff', marginTop: '2px', paddingBlock: '10px', paddingInline: '10px' }}>
        <h4>Balance: {userWallet}</h4>
      </div>
      <div style={{ background: '#fff', marginTop: '10px', paddingBlock: '10px', paddingInline: '10px', borderBottom: '3px solid #fe2379' }}>
        <h4 style={{ textAlign: 'center' }}>Bank or UPI</h4>
      </div>

      <div style={{ marginTop: '20px' }}>
        <Input
          type="text"
          placeholder="Enter Amount"
          value={selectedAmount}
          onChange={(e) => setSelectedAmount(e.target.value)}
          prepend={<img src='https://cdn-icons-png.flaticon.com/128/9382/9382295.png' style={{ height: '20px', width: '20px' }} />}
        />
      </div>
      <ul className='amount-card'>
        {amounts.map((amount) => (
          <li key={amount} onClick={() => handleAmountChange(amount)}>
            {amount}
          </li>
        ))}
      </ul>
      <div style={{ background: '#fff', padding: '5px 30px', marginTop: '5px' }}>
        <p style={{ textAlign: 'left', fontSize: '12px', color: 'gray' }}>
          Tips: Please contact Royale98.in@gmail.com for any recharge or wallet-related failure if it happens.
        </p>
      </div>
      <div className='rechargeOptions'>
        <ul>
          {rechargeOptions.map((option) => (
            <li key={option}>
              <ul>
                <li>
                  {option} (100-50000)
                </li>
                <li>
                  <input
                    type="checkbox"
                    checked={selectedOption === option}
                    onChange={() => setSelectedOption(selectedOption === option ? '' : option)}
                  />
                </li>
              </ul>
            </li>
          ))}
        </ul>
      </div>
      <br />
      <Button btnClass="btn-primary" label="Recharge" clickTo={handleRecharge} />
    </>
  );
}

export default Recharge;
