import React, { useState, useEffect } from 'react';
import GuestHeader from '../../components/GuestHeader';
import Input from '../../components/Input';
import {
  DevicePhoneMobileIcon,
  LockClosedIcon,
  ShieldCheckIcon,
  CodeBracketSquareIcon,
} from '@heroicons/react/24/outline';
import Button from '../../components/Button';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import axios from 'axios';
import { useDispatch } from 'react-redux';

function Register() {
  const [mobileNumber, setMobileNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [loginPassword, setLoginPassword] = useState('');
  const [withdrawPassword, setWithdrawPassword] = useState('');
  const [name, setName] = useState('user');
  const [urlCode, setUrlCode] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [timer, setTimer] = useState(30);

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // Extract verification code from the URL query parameters
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('code');
    if (code) {
      setUrlCode(code);
    }
  }, [location.search]);

  useEffect(() => {
    let interval = null;
    if (otpSent && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setOtpSent(false);
      setTimer(30); // Reset timer for the next OTP request
    }
    return () => clearInterval(interval);
  }, [otpSent, timer]);

  const mobileNumberPattern = /^[6-9]\d{9}$/;

  const sendOtp = async () => {
    if (mobileNumberPattern.test(mobileNumber)) {
      try {
        // Simulate sending OTP (you should replace this with your actual API call)
        const otpNew = Math.floor(Math.random() * 9000) + 1000; // Generate a 4-digit OTP
        dispatch({ type: 'OTP_SENT', payload: otpNew });

        // Simulate a successful OTP send response
        axios.post('https://backend.royale98.in/api/v1/send-otp', { mobile: mobileNumber })
        .then(response => {
            console.log('OTP sent successfully:', response.data);
            setOtpSent(true);
        toast.success('OTP sent successfully!');
            // Handle the successful response here
        })
        .catch(error => {
            console.error('Error sending OTP:', error.response.data);
            
        toast.error('Error Sending otp');
            // Handle the error response here
        });
        
      } catch (error) {
        toast.error('Error sending OTP.');
        console.error('Send OTP error:', error);
      }
    } else {
      toast.error('Please Enter a Valid Mobile Number');
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://backend.royale98.in/api/v1/register', {
        mobile: mobileNumber,
        otp:verificationCode,
        verification_code: urlCode, // Use the code extracted from the URL
        login_password: loginPassword,
        withdraw_password: withdrawPassword,
        name: name,
      });

      if (response.data.response === true) {
        toast.success('Registration successful!');
        dispatch({ type: 'SET_USER_DATA', payload: response.data.user });
        dispatch({ type: 'LOGIN' });
        localStorage.setItem('token', response.data.token);
        navigate('/me');
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error('An error occurred during registration.');
      console.error('Registration error:', error);
    }
  };

  return (
    <>
      <GuestHeader pageTitle="Register" />
      <div style={{ padding: '15px', maxWidth: '500px', margin: 'auto' }}>
        <div style={{borderBottom:'2px solid #ff0c6b', background:'#fff',padding:'20px 10px',width:'90%',fontSize:'15px',color:'#000',marginBlock:'10px',margin:'auto',textAlign:'center'}}>
          <p>Phone Register</p>
        </div>
        <br/>
        <form onSubmit={handleRegister}>
          <Input
            prepend={<><ul className='row row-start'><li><DevicePhoneMobileIcon /></li><li>+91</li></ul></>}
            placeholder="Enter Mobile Number"
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value)}
            readable={otpSent} // Disable input after OTP is sent
          />
          <br />
          <Input
            prepend={<ShieldCheckIcon />}
            placeholder="Verification OTP"
            type="number"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            trailing={
              otpSent ? (
                <Button
                  btnClass="btn-disabled"
                  
                  label={`Wait (${timer}s)`}
                  disabled
                />
              ) : (
                <Button btnClass="btn-primary-outline f-12 border-0"  type="button" label="Send OTP" clickTo={()=>sendOtp()} />
              )
            }
          />
         
         
          <br />
          <Input
            prepend={<LockClosedIcon />}
            placeholder="Login Password"
            type="password"
            showPass={false}
            value={loginPassword}
            onChange={(e) => setLoginPassword(e.target.value)}
          />
          <br />
          <Input
            prepend={<LockClosedIcon />}
            placeholder="Withdraw Password"
            type="password"
            showPass={false}
            value={withdrawPassword}
            onChange={(e) => setWithdrawPassword(e.target.value)}
          />
          <br /> 
          <Input
            prepend={<ShieldCheckIcon />}
            placeholder="Refferal Code"
            type="text"
            value={urlCode}
            onChange={(e) => setUrlCode(e.target.value)}
          />
          <br />
          <Button label="Register" btnClass="btn-primary" type="submit" />
        </form>
        <br />
        <p style={{ textAlign: 'center' }}>
          Already Have an Account? <Link to='/login'>Log In</Link>
        </p>
      </div>
    </>
  );
}

export default Register;
