import React, { useEffect, useState } from 'react';
import AuthHeader from '../../components/AuthHeader';
import { Link, useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { checkRechargeStatus } from '../../reducers/bank-action';

function RechargeChecking() {
  const navigate = useNavigate();
  const [status, setStatus] = useState('pending');
  const { utrcode } = useParams();
  const [token] = useState(localStorage.getItem('token'));
  let intervalId; // Define intervalId outside of useEffect

  const checkStatus = async () => {
    try {
      const response = await checkRechargeStatus(token, { utr_code: utrcode });

      if (response.success) {
        setStatus(response.status);
        toast.success(response.message);
        // Stop checking if the status is success or failed
        if (response.status === 'success' || response.status === 'failed') {
          clearInterval(intervalId);
          setTimeout(()=>{
            window.location.href='/me';
          },3000) // Clear the interval
        }
      } else {
        setStatus(response.status);
      }
    } catch (error) {
      console.error("Error checking recharge status:", error);
      toast.error("An error occurred while processing your request.");
    }
  };

  useEffect(() => {
    intervalId = setInterval(checkStatus, 5000); // Start the interval

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [token, utrcode]);

  return (
    <>
      <AuthHeader pageTitle="Recharge Checking" />
      <div className="checking-container">
        <div className="icon-container">
          {status === 'pending' && (
            <>
              <img 
                style={{ height: '200px', width: '200px' }}
                src="https://cdn-icons-gif.flaticon.com/17105/17105557.gif"
                alt="Pending"
                className="pending-icon"
              />
              <h4>Recharge Pending</h4>
              <p>Please wait while we process your request.</p>
            </>
          )}
          {status === 'success' && (
            <>
              <img 
                style={{ height: '200px', width: '200px' }}
                src="https://cdn-icons-gif.flaticon.com/17204/17204063.gif"
                alt="Success"
                className="pending-icon"
              />
              <h4>Recharge Success</h4>
              <p>Your Recharge is successful</p>
            </>
          )}
          {status === 'failed' && (
            <>
              <img 
                style={{ height: '200px', width: '200px' }}
                src="https://cdn-icons-gif.flaticon.com/17702/17702131.gif"
                alt="Failed"
                className="pending-icon"
              />
              <h4>Recharge Failed</h4>
              <p>Your Recharge is rejected</p>
            </>
          )}
          <p style={{ paddingBlock: '20px', fontSize: '12px' }}>Or</p>
          <Link to="/guess">Go Home</Link>
        </div>
      </div>
    </>
  );
}

export default RechargeChecking;
