import React, { useEffect, useState } from 'react';
import GuestHeader from '../../components/GuestHeader';
import AuthHeader from '../../components/AuthHeader';
import Input from '../../components/Input';
import Button from '../../components/Button';
import TextArea from '../../components/TextArea'; // Assuming you have a TextArea component
import { useDispatch, useSelector } from 'react-redux';
import { loadReport, loadTeam, loadTransactions } from '../../reducers/bank-action';

function Transactions() {
  const transactions = useSelector(state=>state.auth.transactions);
  const dispatch = useDispatch(); 
  
  useEffect(()=>{
    const fetchData = async () => {
  const report = await loadTransactions(localStorage.getItem('token'));
   dispatch({type:'TRANSACTIONS',payload:report  });
    }
    fetchData();
  },[])
  const reportCards = [
    'Subordinates',
    'Today Registrations',
    'Today bet members',
    'Today Login members',
    'Today fist recharge',
    'Total recharge amount',
    'Today first-recharge members',
    'Total withdrawl amount'
  ];


  

  return (
    <>
      <AuthHeader pageTitle="Submit Report" />
      <div className='transactions'>
        {transactions==null &&
        <div className='flex-col h-40'>
          Transactions
          </div>
        }
        {transactions != null &&
        <>
        {transactions.transactions.map((e)=> 
        <>
        <ul>
        <li>
          {e.reference}
        </li>
        <li key={e.id} style={{ color: (e.from_wallet-e.to_wallet) > 0 ? 'green' : 'red' }}>
        {e.from_wallet-e.to_wallet}
</li>

        </ul>
        </>
        )} </>
        }
      </div>
     </>
  );
}

export default Transactions;
