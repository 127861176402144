import React, { useState, useEffect } from 'react';
import GuestHeader from '../../components/GuestHeader';
import AuthHeader from '../../components/AuthHeader';

function About() {
  const [cookiesAccepted, setCookiesAccepted] = useState(false);

  // Check if cookies have already been accepted
  useEffect(() => {
    if (document.cookie.includes('cookies_accepted=true')) {
      setCookiesAccepted(true);
    }
  }, []);

  // Function to handle cookie acceptance
  const acceptCookies = () => {
    document.cookie = "cookies_accepted=true; path=/; max-age=" + 60 * 60 * 24 * 365; // 1 year expiration
    setCookiesAccepted(true);
  };

  return (
    <>
      <AuthHeader pageTitle="About Us" />
      <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif', backgroundColor: '#fff', margin: '10px' }}>
        <h1>About Us</h1>
        
        <div className="content">
          <h2>Cookies Policy</h2>
          <p>We use Cookies to provide You with a better experience when using our Website. By using the Website, You agree to the use of Cookies.</p>

          <h3>Types of Cookies We Use</h3>

          <h4>Notice Acceptance Cookies</h4>
          <p><strong>Type:</strong> Persistent Cookies</p>
          <p><strong>Administered by:</strong> Us</p>
          <p><strong>Purpose:</strong> These Cookies identify if users have accepted the use of cookies on the Website.</p>

          <h4>Functionality Cookies</h4>
          <p><strong>Type:</strong> Persistent Cookies</p>
          <p><strong>Administered by:</strong> Us</p>
          <p><strong>Purpose:</strong> These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.</p>

          <p>For more information about the cookies we use and your choices regarding cookies, please visit our <a href="/cookies-policy">Cookies Policy</a>.</p>
        </div>

        {!cookiesAccepted && (
          <div className="cookie-banner" id="cookieBanner" style={{ backgroundColor: '#333', color: 'white', padding: '20px', textAlign: 'center', position: 'fixed', bottom: '0', width: '200px', zIndex: '9999' }}>
            <span>We use cookies to ensure you get the best experience on our website. By continuing, you accept the use of cookies.</span>
            <button onClick={acceptCookies} style={{ backgroundColor: '#4CAF50', color: 'white', border: 'none', padding: '10px 20px', cursor: 'pointer', marginLeft: '20px', borderRadius: '5px' }}>Accept Cookies</button>
          </div>
        )}
      </div>
    </>
  );
}

export default About;
