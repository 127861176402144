import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { Add, AddAPhoto, BorderBottom } from '@mui/icons-material';
import React, { useState } from 'react'; 

const Accordion = ({ title, children,select,result,data,dt }) => {
  const [isOpen, setIsOpen] = useState(false);
  
  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
  
  return (

    <div style={styles.accordion} className='accord'>
      <div style={styles.accordionHeader} onClick={toggleAccordion}>
        <h3 style={styles.title}><span style={{fontSize:'12px'}}>{title}</span>   <span style={{color:data.Status=='Win'?'green':data.Status=='Wait'?'#ffac00':'red',fontSize:'12px'}}  >{data.Status}</span>  <span style={{color:data.Status=='Win'?'green':data.Status=='Wait'?'#ffac00':'red',fontSize:'12px'}}  >{dt.final_amount}</span> <span>{isOpen ? <ChevronUpIcon style={{height:'20px',width:'20px'}} /> : <ChevronDownIcon  style={{height:'20px',width:'20px'}} />}</span> </h3> 
        <span style={styles.icon}>{isOpen ? <ChevronUpIcon /> : <ChevronDownIcon/>}</span>
      </div>
      <div
        style={{
          ...styles.accordionContent,
          maxHeight: isOpen ? '500px' : '0',
          padding: isOpen ? '5px' : '0 5px',
        }}
      >
        <p style={{fontWeight:'500'}}>
          Period Detail
          
        </p>
        <ul style={{margin:0,padding:0}}>
          {Object.entries(data).map(([key,value])=>(
          <>
          <li>
          {key}
         </li>
         <li>
          {value}
         </li>
         </>
          ))}
          
          
        </ul>
         
      </div>
    </div>
  );
};

const styles = {
  accordion: { 
    borderRadius: '2px',
    margin: '0px 0',
    overflow: 'hidden', 
    transition: 'box-shadow 0.3s ease'
  },
  accordionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    
    alignItems: 'center',
    cursor: 'pointer',
    padding: '5px',
    backgroundColor: '#fff',
    fontSize:'14px',
    transition: 'background-color 0.3s ease',
     
  },
  title: {
    flex:'70%',
    display:'flex',
    flexDirection:'row',
    justifyContent: 'space-between',
    
    alignItems: 'center',
    margin: 0,
    fontSize: '14px',
    fontWeight: '500',
    color: '#333',
    width:'inherit',
  },
  icon: {
     
  },
  accordionContent: {
    backgroundColor: '#fff',
    overflow: 'hidden',
    transition: 'max-height 0.4s ease, padding 0.4s ease',
  },
};

export default Accordion;
