// src/api.js
import axios from 'axios';
import toast from 'react-hot-toast';

const API_URL = 'https://backend.royale98.in/api/v1/bank-cards';
const BASE_URL ='https://backend.royale98.in/api/v1'


export const fetchBankCards = async (token) => {
    try {
        const response = await axios.get(API_URL, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });
        return response.data.cards;
    } catch (error) {
        console.error('Error adding bank card:', error);
        
        // Handle error message
        const errorMessage = error.response?.data?.message || 'An error occurred. Please try again.';
        toast.error(errorMessage);
        
        throw error;
    }
};

export const fetchBankCard = async (token, cardId) => {
    try {
        const response = await axios.get(`${API_URL}/${cardId}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });
        return response.data.card;
    } catch (error) {
        console.error('Error fetching bank card', error);
        throw error;
    }
};

export const addBankCard = async (token, cardData) => {
    try {
        const response = await axios.post(API_URL, cardData, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        }); 
        return response.data;
         
    } catch (error) {
        console.error('Error adding bank card', error);
        toast.success(JSON.stringify(error.errors[0]));
        throw error;
    }
};
export const updateBankCard = async (token, cardId, cardData) => {
    try {
        const response = await axios.put(`${API_URL}/${cardId}`, cardData, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error updating bank card', error);
        toast.error(JSON.stringify(error.response.data.errors[0] || 'Error updating card'));
        throw error;
    }
};

export const addWithdrawRequest = async (token, cardData) => {
    try {
        const response = await axios.post(BASE_URL+'/add-withdraw', cardData, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        }); 
        return response.data;
         
    } catch (error) {
        console.error('Error adding withdraw form card', error);
        return {message:JSON.stringify(error),response:false}
        // throw error;
    }
};

 

export const deleteBankCard = async (token, cardId) => {
    try {
        const response = await axios.delete(`${API_URL}/${cardId}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error deleting bank card', error);
        throw error;
    }
};

export const recharge = async (token, rechargeData) => {
    try {
        const response = await axios.post(BASE_URL+'/recharge', rechargeData, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        // toast.success('Recharge Requested');
        return response.data;
    } catch (error) {
        console.error('Error during recharge', error);
        toast.error(error.response?.data?.message || 'An error occurred during recharge.');
        throw error;
    }
};

export const enterUtr = async (token, rechargeData) => {
    try {
        const response = await axios.post(BASE_URL+'/enter-utr', rechargeData, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        // toast.success('Recharge Requested');
        return response.data;
    } catch (error) {
        console.error('Error during recharge', error);
        toast.error(error.response?.data?.message || 'An error occurred during recharge.');
        throw error;
    }
};

export const checkRechargeStatus = async (token, rechargeData) => {
    try {
        const response = await axios.post(BASE_URL+'/check-status', rechargeData, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        // toast.success('Recharge Requested');
        return response.data;
    } catch (error) {
        console.error('Error during recharge', error);
        toast.error(error.response?.data?.message || 'An error occurred during recharge.');
        throw error;
    }
};

export const loadTeam = async (token) => {
    try {
        const response = await axios.get(`${BASE_URL}/team-info`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        
        return response.data; // Return the response data
    } catch (error) {
        console.error('Error loading team', error);
        return null;
    }
};

export const loadReport = async (token) => {
    try {
        const response = await axios.get(`${BASE_URL}/report-info`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        
        return response.data; // Return the response data
    } catch (error) {
        console.error('Error loading team', error);
        // toast.error(error.response?.data?.message || 'An error occurred while loading team.');
        // throw error;
    }
};

export const applyBalance = async (token) => {
    try {
        const response = await axios.post(`${BASE_URL}/promotion/apply`,{}, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        
        return response.data; // Return the response data
    } catch (error) {
        console.error('Error loading team', error);
        // toast.error(error.response?.data?.message || 'An error occurred while loading team.');
        // throw error;
    }
};
export const loadTransactions = async (token) => {
    try {
        const response = await axios.get(`${BASE_URL}/transactions-info`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        console.log(response.data);
        return response.data; // Return the response data
    } catch (error) {
        console.error('Error loading team', error);
        // toast.error(error.response?.data?.message || 'An error occurred while loading team.');
        // throw error;
    }
};
export const loadWallet = async () => {
    try {
        const token=localStorage.getItem('token');
        const response = await axios.get(`${BASE_URL}/me`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        }); 
        return response.data; // Return the response data
    } catch (error) {
        console.error('Error loading team', error);
        // toast.error(error.response?.data?.message || 'An error occurred while loading team.');
        // throw error;
    }
};

