import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import AuthHeader from '../../components/AuthHeader';
import Input from '../../components/Input';
import Button from '../../components/Button';
import toast from 'react-hot-toast';
import { enterUtr, loadTeam } from '../../reducers/bank-action';
import img from '../../images/recharge-help.jpeg'
import { useSelector } from 'react-redux';

function UTRInput() {
 
  const navigate = useNavigate();
  
  const team = useSelector(state => state.auth.team);
  const { utrcode } = useParams();
  const [token] = useState(localStorage.getItem('token'));
  const [utr, setUtr] = useState('');
  const [balance, setBalance] = useState(30); // Assume initial balance
  const amt=localStorage.getItem('amount')??100;
  useEffect(() => {
    if (!token) {
      toast.error("You need to be logged in to access this page.");
      navigate('/login'); // Redirect to login page
    }
  }, [token, navigate]);

  const handleUtrChange = (e) => {
    setUtr(e.target.value);
  };
  

  const copyUpi = () => {
    navigator.clipboard.writeText(team.upi1)
      .then(() => {
        toast.success("UPI address copied to clipboard!");
      })
      .catch((error) => {
        toast.error("Failed to copy UPI address.");
        console.error("Error copying UPI address:", error);
      });
  };

  const handleSubmit = async () => {
    if (!utr) {
      toast.error("Please enter your UTR number.");
      return;
    }

    try {
      const response = await enterUtr(token, {
        user_id: 1, // Replace with actual user ID
        utr_code: utrcode, // Use the UTR code from the URL params
        utr_id: utr, // Use the UTR input from the state
      });

      if (response.success) {
        toast.success(response.message);
        navigate(`/recharge-check/${utrcode}`);
      } else {
        toast.error("Submission failed: " + response.message);
      }
    } catch (error) {
      console.error("Error during UTR submission:", error);
      toast.error("An error occurred while processing your request.");
    }
  };

  return (
    <>
      <AuthHeader pageTitle="Payment Information" />
      <div style={{paddingBlock:'10px', backgroundColor:'#fff',textAlign:'center',marginTop:'10px'}}>
      <a href="#" style={{textAlign:'center'}}>How to pay ?</a>
       
       <h5 style={{marginTop:'20px',fontWeight:'bolder'}}>Payment Amount</h5>
       <h1 style={{color:'orangered',fontWeight:'bolder',marginBottom:'10px'}}>₹ {amt}</h1>

       
         <ul className='row' style={{borderTop:'1px solid gray',justifyContent:'space-between',borderBottom:'1px solid gray',paddingBlock:'10px',paddingInline:'10px'}}>
          <li style={{textAlign:'left',fontSize:'14px'}}>
            <p>Pay  to UPI</p>
            <p className='text-primary' style={{fontWeight:'500'}}>{team==null?'N/A':team.upi1}</p>
          </li>
          <li>
            <button type='button' onClick={copyUpi} className='btn btn-primary' style={{width:'auto',paddingInline:'10px'}}>Copy</button>
          </li>
         </ul>

         <ul  className='row' style={{fontSize:'12px', justifyContent:'space-between',paddingBlock:'10px',paddingInline:'10px'}}>
           <li >
            <p style={{fontWeight:'600',marginBlockEnd:'10px'}}>STEP 1</p>
            <p style={{fontWeight:'600'}}>STEP 2</p>
           </li>
           <li style={{flex:'0.9'}}>
            <p style={{textAlign:'left'}}>Open online banking or wallet transfer to the UPI account</p>
             <input type="text"
          placeholder="Enter REF No. 20190xxxxxxxx"
          style={{textAlign:'left',width:'100%',border:'1px solid gray'}}
          value={utr}
          onChange={handleUtrChange} />
           </li>
         </ul>
       
      </div>
       
     

      
      <div style={{ background: '#fff', padding: '5px 30px' }}>
        <p style={{ textAlign: 'justify', fontSize: '12px', color: 'gray' }}>
          You must fill in the correct REF No , then click the button below and wait for it to arraive
        </p>
      </div>
      <br />
       
      <Button btnClass="btn-primary" label="Confirm REF No." clickTo={handleSubmit} />
      <br/>
      <div style={{textAlign:'center'}}>
        
      <img src={img} style={{width:'100%',margin:'auto'}}/>

      </div>          </>
  );
}

export default UTRInput;
