import { MinusCircleIcon, PlusCircleIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';

function AccountOptionItem({ icon, label, clickTo, isAccordian = false,childs=[] }) {

  const [isOpen ,setIsOpen]=useState(false);
  return (
    <div className='profile__option__item' >
      <ul className='row row-start align-center'  style={{ padding: '8px' }}  >
        <li className='hover-cursor' style={{ flex: 0.05, marginInlineEnd: '10px' }}>
          {icon}
        </li>
        <li className='hover-cursor' style={{ flex: 0.95, fontSize: '14px', color: 'rgba(0,0,0,0.9)' }} onClick={clickTo}>
          {label} 
        </li>
        {isAccordian && (
        <li  style={{height:'20px',width:'20px'}} onClick={()=>setIsOpen(!isOpen)}>
         {isOpen?<MinusCircleIcon/>: <PlusCircleIcon/>}
        </li>
        )}
      </ul>

      {isAccordian && (
        <div className={`accordian_body ${isOpen?'visible':''}`}>
          {childs.map((child)=>(<ul className='row row-start align-center' style={{ padding: '8px' }} onClick={child.clickTo}>
            <li className='hover-cursor' style={{ flex: 0.05, marginInlineEnd: '10px' }}>
               
            </li>
            <li className='hover-cursor' style={{ flex: 0.95, fontSize: '14px', color: 'rgba(0,0,0,0.9)' }}>
              {child.label} 
            </li>
          </ul>))}
        </div>
      )}
    </div>
  );
}

export default AccountOptionItem;
