  import { Avatar, TextField } from '@mui/material'
  import React, { useState } from 'react' 
  import { useDispatch, useSelector } from 'react-redux'
  import { useNavigate } from 'react-router-dom';
  import  Button from './Button';
  import { updateUserProfile } from '../reducers/actions';

  import toast from 'react-hot-toast';
  

  function ProfileCard() {
    const user = useSelector(state => state.auth.user);
    const userWallet = useSelector(state => state.auth.wallet);
    const [detailModal, setDetailsModal] = useState(false);
    const [name, setName] = useState(user.name || '');
    const [profileImage, setProfileImage] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const handleNameChange = (e) => {
      setName(e.target.value);
    };

    const handleImageChange = (e) => {
      const file = e.target.files[0];
      if (file) {
        setProfileImage(URL.createObjectURL(file));
      }
    };

    const handleSubmit = (e) => {
      e.preventDefault(); // Prevent default form submission behavior
    
      // Create FormData object
      const formData = new FormData();
      formData.append('name', name); // Append the user's name to the form data
    
      // Append the profile image if it exists
      if (profileImage) {
        formData.append('profileImage', profileImage);
      }
    
      // Dispatch the action to update the user profile
      dispatch(updateUserProfile(formData))
        .then(() => {
          // Handle any additional logic if needed after successful dispatch
          setDetailsModal(false); // Close the modal
        })
        .catch((error) => {
          // Handle any error that occurred during the dispatch
          console.error('Error updating profile:', error);
          toast.error('Failed to update profile. Please try again.');
        });
    };
    
    return (
      <>
      

        {detailModal && (
          <div className='modal-container'>
            <div className='modal-box' style={{padding:'20px'}}>
              <form className='form-box' onSubmit={handleSubmit}>
                <TextField
                  label='Name'
                  variant='outlined'
                  fullWidth
                  value={name}
                  onChange={handleNameChange}
                />
                <input
                  
                type='file'
                  accept='image/*'
                  onChange={(e) => setProfileImage(e.target.files[0])}
                  style={{ marginTop: '16px' }}
                />
                <br/>
                 
                
                <ul className='row row-around'>
                  <li style={{width:'300px'}}>
                  <Button label="Close" btnClass="btn-primary-outline"  clickTo={()=>setDetailsModal(false)}  custom={true}/>
                  </li>
                  <li style={{width:'300px'}}>
                  <Button label="Save" btnClass="btn-primary"    custom={true}/>
                  </li>
                </ul>
              </form>
            </div>
          </div>)}
    
      <div className='profile__card' style={{padding:'20px 10px'}}>
          <ul className='row row-start'>
              <li> 
                {user.avatar=='user.png' &&  <Avatar sx={{ height: '30px', width: '30px' }}/>}
                {user.avatar!='user.png' && <img src={user.avatar} style={{height:'40px',width:'40px',borderRadius:'50%'}} />}
                  
              </li>
              <li style={{paddingInlineStart:'5px'}}>
                  <ul>
                      <li>User  <span className=''>{user.name}</span></li>
                      <li>ID <span className=''>{user.ref_code}</span></li>
                      <li>Mobile <span className=''>+91-{user.mobile}</span></li>
                      <li>Wallet <span className=''>{userWallet}</span></li>
                  </ul>
                  
                  <ul class='row row-between' style={{marginTop:'15px'}}>
        <li>
          <Button label="Recharge" btnClass="btn-primary-outline f-12"  clickTo={()=>navigate('/recharge')}  custom={true}/>
        </li>
        <li>
          <Button label="Change Nickname" btnClass="btn-primary f-12" clickTo={()=>setDetailsModal(true)}   custom={true}/>
        </li>
      </ul>
              </li>
          </ul> 
        
      </div>
      </>
    )
  }

  export default ProfileCard