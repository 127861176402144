import { TrophyIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import Accordion from './Accordian';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

function GameOrderRecord() {
  const game = useSelector(state => state.auth.currentGame);  
  const records = useSelector(state => state.auth.records);
  
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState([]);
  const itemsPerPage = 10; // Set the number of items per page
  
  // Filter the records based on the current game
  const filteredRecords = records.filter((e) => e.in_game === game.toString().toLowerCase());
  
  // Calculate total number of pages
  const totalPages = Math.ceil(filteredRecords.length / itemsPerPage);

  // Pagination handlers
  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    // Get the starting and ending indices for pagination
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    // Slice the filteredRecords based on the current page
    const data = filteredRecords.slice(startIndex, endIndex);

    // Set the current page data
    setCurrentData(data);
    
  }, [currentPage, filteredRecords, itemsPerPage]);

  return (
    <div className='gameresult__record'> 
      <div className='heading'>
        <span><TrophyIcon style={{ height: '20px', width: '20px' }} /></span> {game} Order Result
      </div>
      
      {/* Display the total number of records */}
       

      {/* Display each record in currentData */}
      {currentData.length > 0 ? (
        currentData.map((gr) => (
          <Accordion 
            key={gr.id} 
            data={{
              "Period": gr.game_id,
              "Contract Money": parseInt(gr.amount / gr.token),
              "Contract Count": gr.token,
              "Delivery": gr.amount * 0.98,
              "Fee": gr.amount * 0.02,
              "Select": gr.selection,
              "Status": gr.status === 1 ? 'Win' : gr.status === 0 ? 'Wait' : 'Lose',
              "Amount": gr.final_amount,
              "Create time": gr.created_at
            }} 
            title={gr.game_id} 
            select={gr.selection} 
            result={"aa"} 
            dt={gr}
          >
            <p>This is the content for section {gr.game_id}.</p>
          </Accordion>
        ))
      ) : (
        <p>No records available for this game.</p>
      )}

      {/* Pagination controls */}
      <ul className='row row-between' style={{ paddingBlock: '5px' }}>
        <li style={{ height: '20px', width: '20px', cursor: 'pointer' }} onClick={handlePrev}>
          <ChevronLeftIcon />
        </li>
        <li>
          <span>{currentPage}</span>/<span>{totalPages}</span>
        </li>
        <li style={{ height: '20px', width: '20px', cursor: 'pointer' }} onClick={handleNext}>
          <ChevronRightIcon />
        </li>
      </ul>
      
      <br/><br/>
    </div>
  );
}

export default GameOrderRecord;
