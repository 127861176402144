import React, { useState, useEffect } from 'react';
import GuestHeader from '../../components/GuestHeader';
import Input from '../../components/Input';
import {
  DevicePhoneMobileIcon,
  LockClosedIcon,
  CodeBracketIcon,
} from '@heroicons/react/24/outline';
import Button from '../../components/Button';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import axios from 'axios';

function ForgetPassword() {
  const [mobile, setMobile] = useState('');
  const [otp, setOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [timer, setTimer] = useState(30);
  const navigate = useNavigate();

  useEffect(() => {
    let interval = null;
    if (otpSent && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setOtpSent(false);
      setTimer(30);
    }
    return () => clearInterval(interval);
  }, [otpSent, timer]);

  const mobileNumberPattern = /^[6-9]\d{9}$/;

  const sendOtp = async () => {
    if (mobileNumberPattern.test(mobile)) {
      try {
        const response = await axios.post('https://backend.royale98.in/api/v1/send-otp', { mobile });
        if (response.data.response) {
          toast.success('OTP sent successfully!');
          setOtpSent(true);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error('Error sending OTP. Please try again.');
      }
    } else {
      toast.error('Please enter a valid mobile number.');
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    

    try {
      const response = await axios.post('https://backend.royale98.in/api/v1/reset-password', {
        mobile,
        otp,
        new_password: newPassword,
      });

      if (response.data.response) {
        toast.success('Password reset successful!');
        navigate('/login'); // Redirect to login or appropriate page
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error('An error occurred during password reset.');
    }
  };

  return (
    <>
      <GuestHeader pageTitle="Forget Password" />
      <div style={{ padding: '15px', maxWidth: '500px', margin: 'auto' }}>
        <form onSubmit={handleResetPassword}>
          <Input
            prepend={<DevicePhoneMobileIcon />}
            placeholder="Enter Mobile Number"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
          />
          <br />
          <Input
            prepend={<CodeBracketIcon />}
            placeholder="Verification Code"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            trailing={
              otpSent ? (
                <Button
                  btnClass="btn-disabled"
                  label={`Wait (${timer}s)`}
                  disabled
                />
              ) : (
                <Button
                  btnClass="btn-primary-outline"
                  type="button"
                  label="Send OTP"
                  clickTo={sendOtp} // This is the button that sends the OTP
                />
              )
            }
          />
          <br />
          <Input
            prepend={<LockClosedIcon />}
            placeholder="New Password"
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <br />
           
          <br />
          <Button label="Reset Password" btnClass="btn-primary" type="submit" />
        </form>
      </div>
    </>
  );
}

export default ForgetPassword;
