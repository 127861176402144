import axios from 'axios';
import toast from 'react-hot-toast';

// Define your base URL and token
const BASE_URL = 'https://backend.royale98.in/api/v1';
const token = localStorage.getItem('token') ?? '';

// Action to update user profile
export const updateUserProfile = (formData) => async (dispatch) => {
  try {
    var token=localStorage.getItem('token');
    const response = await axios.post(`${BASE_URL}/update-profile`, formData, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data', // Make sure to set the correct content type for file uploads
      },
    });

    toast.success(response.data.message)
    if(response.data.response==true){
        dispatch({type:'SET_USER_DATA',payload:response.data.user})
    }
    // Handle successful response and dispatch relevant actions
    // dispatch({ type: 'UPDATE_PROFILE_SUCCESS', payload: response.data });
  } catch (error) {
     console.error('Error updating profile', error);
    // dispatch({ type: 'UPDATE_PROFILE_FAILURE', error });
    toast.error(JSON.stringify(error))
  }
};

export const updateWPass = (formData) => async (dispatch) => {
  try {
    var token=localStorage.getItem('token');
    const response = await axios.post(`${BASE_URL}/reset-w-pass`, formData, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data', // Make sure to set the correct content type for file uploads
      },
    });

    toast.success(response.data.message)
    if(response.data.response==true){ 
    }
    // Handle successful response and dispatch relevant actions
    // dispatch({ type: 'UPDATE_PROFILE_SUCCESS', payload: response.data });
  } catch (error) {
     console.error('Error updating profile', error);
    // dispatch({ type: 'UPDATE_PROFILE_FAILURE', error });
    toast.error(JSON.stringify(error))
  }
};

export const fetchwallet=()=>async (dispatch)=>{
  try{
    const response = await axios.get(BASE_URL+"/me",{
    headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data', // Make sure to set the correct content type for file uploads
      },
    });

     
    if(response.data.response==true){
      dispatch({ type: 'SET_USER_WALLET', payload: response.data.wallet }); 
                
    }
  }catch(error){

  }
}
